import type { SliceTemplateProps } from '@yoweb/prismic-slicemachine/slices/slicesTypes';
import { ColoredCtaSectionSliceType } from '@yoweb/prismic-slicemachine/slices/ColoredCtaSectionSlice';
import { AppDownloadCtaSectionSliceType } from '@yoweb/prismic-slicemachine/slices/AppDownloadCtaSectionSlice';
import { CornerCardSectionSliceType } from '@yoweb/prismic-slicemachine/slices/CornerCardSectionSlice';
import { DividerSliceType } from '@yoweb/prismic-slicemachine/slices/DividerSlice';
import { SeoSliceType } from '@yoweb/prismic-slicemachine/slices/SeoSlice';
import { VerticalScrollCarouselSliceType } from '@yoweb/prismic-slicemachine/slices/VerticalScrollCarouselSlice';
import { HeroSectionSliceType } from '@yoweb/prismic-slicemachine/slices/HeroSectionSlice';
import { DreamGallerySliceType } from '@yoweb/prismic-slicemachine/slices/DreamGallerySlice';
import { TestimonialSectionSliceType } from '@yoweb/prismic-slicemachine/slices/TestimonialSectionSlice';
import { AnimatedColoredSectionSliceType } from '@yoweb/prismic-slicemachine/slices/AnimatedColoredSectionSlice';
import { ExplanationSectionSliceType } from '@yoweb/prismic-slicemachine/slices/ExplanationSectionSlice';
import { FaqSliceType } from '@yoweb/prismic-slicemachine/slices/FaqSlice';
import { ContentImageSectionSliceType } from '@yoweb/prismic-slicemachine/slices/ContentImageSectionSlice';
import { MediaHeaderSliceType } from '@yoweb/prismic-slicemachine/slices/MediaHeaderSlice';
import animationData from '@yoweb/ui/lottieAnimations/juggling-a-lot.json';
import SeoSlice from '@yoweb/prismic-slicemachine/slices/SeoSlice/SeoSlice';
import HeroSectionSlice from '@yoweb/prismic-slicemachine/slices/HeroSectionSlice/HeroSectionSlice';
import DividerSlice from '@yoweb/prismic-slicemachine/slices/DividerSlice/DividerSlice';
import CornerCardSectionSlice from '@yoweb/prismic-slicemachine/slices/CornerCardSectionSlice/CornerCardSectionSlice';
import DreamGallerySlice from '@yoweb/prismic-slicemachine/slices/DreamGallerySlice/DreamGallerySlice';
import ExplanationSectionSlice from '@yoweb/prismic-slicemachine/slices/ExplanationSectionSlice/ExplanationSectionSlice';
import ContentImageSectionSlice from '@yoweb/prismic-slicemachine/slices/ContentImageSectionSlice/ContentImageSectionSlice';
import FaqSlice from '@yoweb/prismic-slicemachine/slices/FaqSlice/FaqSlice';
import TestimonialSectionSlice from '@yoweb/prismic-slicemachine/slices/TestimonialSectionSlice/TestimonialSectionSlice';
import AnimatedColoredSectionSlice from '@yoweb/prismic-slicemachine/slices/AnimatedColoredSectionSlice/AnimatedColoredSectionSlice';
import MediaHeaderSlice from '@yoweb/prismic-slicemachine/slices/MediaHeaderSlice/MediaHeaderSlice';
import VerticalScrollCarouselSlice from '@yoweb/prismic-slicemachine/slices/VerticalScrollCarouselSlice/VerticalScrollCarouselSlice';
import ColoredCtaSectionSlice from '@yoweb/prismic-slicemachine/slices/ColoredCtaSectionSlice/ColoredCtaSectionSlice';
import AppDownloadCtaSectionSlice from '@yoweb/prismic-slicemachine/slices/AppDownloadCtaSectionSlice/AppDownloadCtaSectionSlice';
import LargeVideoSectionSlice, {
  LargeVideoSectionSliceType,
} from '@yoweb/prismic-slicemachine/slices/LargeVideoSectionSlice';
import HeroWithFormSlice, {
  HeroWithFormSliceType,
} from '@yoweb/prismic-slicemachine/slices/HeroWithFormSlice';
import ColoredCtaWithFormSlice, {
  ColoredCtaWithFormSliceType,
} from '@yoweb/prismic-slicemachine/slices/ColoredCtaWithFormSlice';
import CardCarouselSectionSlice, {
  CardCarouselSectionSliceType,
} from '@yoweb/prismic-slicemachine/slices/CardCarouselSectionSlice';
import ChatMessageSlice, {
  ChatMessageSliceType,
} from '@yoweb/prismic-slicemachine/slices/ChatMessageSlice';
import type { ChatMessageSliceResponse } from '@yoweb/prismic-slicemachine/slices/ChatMessageSlice/ChatMessageSliceTypes';
import { ErrorDisplay } from '@yoweb/ui/components/form';
import MarketingCarouselSlice, {
  MarketingCarouselSliceType,
} from '@yoweb/prismic-slicemachine/slices/MarketingCarouselSlice';
import ProjectCardSlice, {
  ProjectCardSliceType,
} from '@yoweb/prismic-slicemachine/slices/ProjectCardSlice';
import SmallPrintSlice, {
  SmallPrintSliceType,
} from '@yoweb/prismic-slicemachine/slices/SmallPrintSlice';
import { Spacer } from '@yoweb/ui/components/Spacer';
import AccordionColorGroupsSectionSlice, {
  AccordionColorGroupsSectionSliceType,
} from '@yoweb/prismic-slicemachine/slices/AccordionColorGroupsSectionSlice';
import VideoColoredSectionSlice, {
  VideoColoredSectionSliceType,
} from '@yoweb/prismic-slicemachine/slices/VideoColoredSectionSlice';
import CarouselStatsSlice, {
  CarouselStatsSliceType,
} from '@yoweb/prismic-slicemachine/slices/CarouselStatsSlice';
import ColoredCtaWithFigureSlice, {
  ColoredCtaWithFigureSliceType,
} from '@yoweb/prismic-slicemachine/slices/ColoredCtaWithFigureSlice';
import ImagesWithQuoteSlice, {
  ImagesWithQuoteSliceType,
} from '@yoweb/prismic-slicemachine/slices/ImagesWithQuoteSlice';
import VideoThumbnailsSlice, {
  VideoThumbnailsSliceType,
} from '@yoweb/prismic-slicemachine/slices/VideoThumbnailsSlice';
import ParagraphSlice, {
  ParagraphSliceType,
} from '@yoweb/prismic-slicemachine/slices/ParagraphSlice';
import HeroWithColoredBackgroundSlice, {
  HeroWithColoredBackgroundSliceType,
} from '@yoweb/prismic-slicemachine/slices/HeroWithColoredBackgroundSlice';
import RoundedImageSlice, {
  RoundedImageSliceType,
} from '@yoweb/prismic-slicemachine/slices/RoundedImageSlice';
import ImageHeaderSlice, {
  ImageHeaderSliceType,
} from '@yoweb/prismic-slicemachine/slices/ImageHeaderSlice';
import WebinarSectionSlice, {
  WebinarSectionSliceType,
} from '@yoweb/prismic-slicemachine/slices/WebinarSectionSlice';
import ReviewSectionSlice, {
  ReviewSectionSliceType,
} from '@yoweb/prismic-slicemachine/slices/ReviewSectionSlice';
import LargeMediaSectionSlice, {
  LargeMediaSectionSliceType,
} from '@yoweb/prismic-slicemachine/slices/LargeMediaSectionSlice';
import WhatWeDoSectionSlice, {
  WhatWeDoSectionSliceType,
} from '@yoweb/prismic-slicemachine/slices/WhatWeDoSectionSlice';
import PricingModuleSlice, {
  PricingModuleSliceType,
  type PricingProps,
} from '@yoweb/prismic-slicemachine/slices/PricingModuleSlice';
import type { UIProps } from '@yoweb/prismic-slicemachine/slices/PricingModuleSlice/PricingModuleSliceTypes';

export const HomeTemplate = ({
  slice,
  slices,
  sliceIndex,
  children,
  templateProps,
}: SliceTemplateProps) => {
  // Props for pricing section
  const basePrice = templateProps?.basePrice;
  const hasCoupon = templateProps?.hasCoupon;
  const useSubscriptionTrialRepeatingUi = templateProps?.useSubscriptionTrialRepeatingUi;
  const useSubscriptionTrialOnceUi = templateProps?.useSubscriptionTrialOnceUi;
  const discountMonths = templateProps?.discountMonths;
  const discountPrice = templateProps?.discountPrice;
  const discountAmountFull = templateProps?.discountAmountFull;
  const initialOfferMonths = templateProps?.initialOfferMonths;
  const initialOfferDays = templateProps?.initialOfferDays;
  const initialOfferPrice = templateProps?.initialOfferPrice;
  const initialOfferPriceFormatted = templateProps?.initialOfferPriceFormatted;
  const disclaimerPretext = templateProps?.disclaimerPretext;
  const disclaimerLink = templateProps?.disclaimerLink;

  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (slice.slice_type) {
    case SeoSliceType:
      return <SeoSlice slice={slice} />;

    case HeroSectionSliceType:
      return <HeroSectionSlice slice={slice} mobilePosition="70% 50%" />;

    case DividerSliceType:
      return <DividerSlice slice={slice} />;

    case CornerCardSectionSliceType:
      return <CornerCardSectionSlice slice={slice} />;

    case DreamGallerySliceType:
      return <DreamGallerySlice slice={slice} />;

    case ExplanationSectionSliceType:
      return <ExplanationSectionSlice slice={slice} />;

    case ContentImageSectionSliceType:
      return <ContentImageSectionSlice slice={slice} />;

    case FaqSliceType:
      return <FaqSlice slice={slice} />;

    case TestimonialSectionSliceType:
      return <TestimonialSectionSlice data-testid="homepage-testimonial-section" slice={slice} />;

    case AnimatedColoredSectionSliceType:
      return <AnimatedColoredSectionSlice slice={slice} animationData={animationData} />;

    case MediaHeaderSliceType:
      return <MediaHeaderSlice slice={slice} />;

    case VerticalScrollCarouselSliceType:
      return (
        <VerticalScrollCarouselSlice
          slice={slice}
          dataTestId="homepage-vertical-carousel"
          testIdPrefix="homepage"
        />
      );

    case ColoredCtaSectionSliceType: {
      const coloredCtaSectionSlices = slices
        .map((_slice, idx) => (_slice.slice_type === ColoredCtaSectionSliceType ? idx : undefined))
        .filter(Boolean);

      const isFirstSlice = sliceIndex === coloredCtaSectionSlices[0];
      const isSecondSlice = sliceIndex === coloredCtaSectionSlices[1];

      if (isFirstSlice) {
        return <ColoredCtaSectionSlice slice={slice} backgroundColor="base000" />;
      } else if (isSecondSlice) {
        return (
          <ColoredCtaSectionSlice slice={slice} backgroundColor="coral" pb={{ _: 'xl1', lg: 90 }} />
        );
      }

      return <ColoredCtaSectionSlice slice={slice} />;
    }

    case AppDownloadCtaSectionSliceType: {
      const AppDownloadCtaSectionSlices = slices
        .map((_slice, idx) =>
          _slice.slice_type === AppDownloadCtaSectionSliceType ? idx : undefined,
        )
        .filter(Boolean);

      const isFirstSlice = sliceIndex === AppDownloadCtaSectionSlices[0];
      const isSecondSlice = sliceIndex === AppDownloadCtaSectionSlices[1];

      if (isFirstSlice) {
        return <AppDownloadCtaSectionSlice slice={slice} backgroundColor="base000" />;
      } else if (isSecondSlice) {
        return (
          <AppDownloadCtaSectionSlice
            slice={slice}
            backgroundColor="coral"
            pb={{ _: 'xl1', lg: 90 }}
          />
        );
      }

      return <AppDownloadCtaSectionSlice slice={slice} />;
    }

    case LargeVideoSectionSliceType:
      return <LargeVideoSectionSlice slice={slice} />;

    case HeroWithFormSliceType:
      return <HeroWithFormSlice slice={slice}>{children}</HeroWithFormSlice>;

    case ColoredCtaWithFormSliceType:
      return <ColoredCtaWithFormSlice slice={slice}>{children}</ColoredCtaWithFormSlice>;

    case CardCarouselSectionSliceType: {
      const chatMessageSlice = slices.find(
        (_slice) => _slice.slice_type === ChatMessageSliceType,
      ) as ChatMessageSliceResponse | undefined;

      return (
        <CardCarouselSectionSlice
          slice={slice}
          id={`chat-reveal-carousel-section-${slice.primary.title
            .toLowerCase()
            .split(' ')
            .join('-')}`}
          background="base050"
        >
          {chatMessageSlice
            ? slice.items.map((item) => (
                <ChatMessageSlice
                  key={item.label}
                  slice={chatMessageSlice}
                  cardCarouselItem={item}
                />
              ))
            : // eslint-disable-next-line react/jsx-no-literals
              [
                <ErrorDisplay key="missing" title={`Prismic CMS error occurred.`} withBorder>
                  {
                    // eslint-disable-next-line react/jsx-no-literals
                    'CardCarouselSectionSlice requires ChatMessageSlice. Please add data to the ChatMessageSlice.'
                  }
                </ErrorDisplay>,
              ]}
        </CardCarouselSectionSlice>
      );
    }

    case MarketingCarouselSliceType:
      return <MarketingCarouselSlice slice={slice} />;

    case ProjectCardSliceType:
      return <ProjectCardSlice slice={slice} />;

    case SmallPrintSliceType:
      return (
        <>
          <Spacer size={{ _: 'medium2', lg: 'small3' }} />
          <SmallPrintSlice slice={slice} />
          <Spacer size="normal3" />
        </>
      );

    case AccordionColorGroupsSectionSliceType:
      return (
        <AccordionColorGroupsSectionSlice
          data-testid="accordion-color-groups-slice"
          slice={slice}
        />
      );

    case VideoColoredSectionSliceType:
      return <VideoColoredSectionSlice slice={slice} />;

    case CarouselStatsSliceType:
      return <CarouselStatsSlice slice={slice} />;

    case ColoredCtaWithFigureSliceType:
      return <ColoredCtaWithFigureSlice slice={slice} />;

    case ImagesWithQuoteSliceType:
      return <ImagesWithQuoteSlice slice={slice} />;

    case VideoThumbnailsSliceType:
      return <VideoThumbnailsSlice slice={slice} />;

    case ParagraphSliceType:
      return <ParagraphSlice slice={slice} />;

    case HeroWithColoredBackgroundSliceType:
      return <HeroWithColoredBackgroundSlice slice={slice} />;

    case RoundedImageSliceType:
      return <RoundedImageSlice slice={slice} />;

    case ImageHeaderSliceType:
      return <ImageHeaderSlice slice={slice} />;

    case WebinarSectionSliceType:
      return <WebinarSectionSlice slice={slice} />;

    case ReviewSectionSliceType:
      return <ReviewSectionSlice slice={slice} />;

    case LargeMediaSectionSliceType:
      return <LargeMediaSectionSlice slice={slice} />;

    case WhatWeDoSectionSliceType:
      return <WhatWeDoSectionSlice slice={slice} />;

    case PricingModuleSliceType: {
      const props = {
        basePrice,
        hasCoupon,
        useSubscriptionTrialOnceUi,
        useSubscriptionTrialRepeatingUi,
        discountMonths,
        discountPrice,
        discountAmountFull,
        initialOfferMonths,
        initialOfferDays,
        initialOfferPrice,
        initialOfferPriceFormatted,
        disclaimerPretext,
        disclaimerLink,
      };
      return <PricingModuleSlice slice={slice} {...(props as PricingProps & UIProps)} />;
    }
    default:
      return null;
  }
};
